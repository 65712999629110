@import '../_main'
@import 'about'

.splash
  .heading
    display: flex
    width: 100%

    .broken-heart
      svg
        height: 8em

  h1
    width: 3em

  h2
    a
      color: $white
      text-decoration: underline

      &:active
        text-decoration: none

      &:hover
        text-decoration: none

  @media screen and (min-width: $medium)
    h1
      width: max-content

    .heading
      align-items: baseline

      .broken-heart 
        margin-left: 12px

        svg
          height: $splashH1size
